<template>
  <div class="rn_my_wrap">
    <GoodsSearch />
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section goods_border">
      <!---->
      <div class="cate_wrap">
        <div class="cate_left">
          <router-link to="/market_category_list"
            ><img src="@/assets/images/sub/cate_menu_ico.png" alt=""
          /></router-link>
        </div>
        <div class="cate_right">
          <!-- -->
          <GoodsCategory
            :category="categories"
            :id="id"
            ref="goodsCategory"
            @getGoodsCategory="getGoodsCategory"
          />
          <!-- -->
        </div>
      </div>
      <!---->
      <div class="rn_ms_box_4">
        <div class="sel_sort">
          <div>
            <router-link
              to=""
              class="list_sort on"
              @click.native="listSort('newly', $event)"
              >최신순</router-link
            >
            <router-link
              to=""
              class="list_sort"
              @click.native="listSort('popular_sales', $event)"
              >판매순</router-link
            >
            <router-link
              to=""
              class="list_sort"
              @click.native="listSort('reviewer', $event)"
              >리뷰수순</router-link
            >
          </div>
        </div>
        <div class="sect_location">
          <span>상품</span>
        </div>

        <div class="main_list">
          <template v-for="(items, index) in listArray">
            <div class="sect_box" :key="index" v-if="items.length > 0">
              <div class="top">
                <div class="left">{{ items[0].category_name }}</div>
                <div class="right">
                  <router-link
                    :to="`/market_sub_list/${items[0].level}/${items[0].id}`"
                    >하위 카테고리 가기<img
                      src="@/assets/images/sub/my_move_ico.png"
                      alt=""
                  /></router-link>
                </div>
              </div>
              <div class="list_box">
                <template v-for="(item, idx) in items">
                  <div
                    class="box"
                    :key="`detail_${idx}_${item.goods_seq}`"
                    :data-idx="idx"
                    :data-length="items.length"
                    :style="
                      idx + 1 >= items.length && items.length % 2 !== 0
                        ? 'border-right:1px solid #cccccc'
                        : ''
                    "
                    v-if="item.goods_seq"
                  >
                    <router-link
                      :to="`/market_goods_detail/${items[0].id}/${item.goods_seq}`"
                    >
                      <div class="img">
                        <img
                          :src="item.image"
                          @error="replaceImg"
                          :alt="item.goods_name"
                        />
                      </div>
                      <div class="txt">
                        <div class="name">
                          {{ item.goods_name }}
                        </div>
                        <div class="price">
                          {{ won(item.sale_price) }}원<span
                            v-if="parseInt(item.sale_per) > 0"
                            >{{ item.sale_per }}%</span
                          >
                        </div>
                      </div>
                    </router-link>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div
          class="rn_more"
          v-if="parseInt(totalPage) > parseInt(categoryPage)"
        >
          <router-link to="" @click.native="getMarketMainList()">
            더보기<img src="@/assets/images/sub/my_down_ico.png" alt=""
          /></router-link>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
import GoodsCategory from "@/components/shop/menu/GoodsCategory";
import GoodsSearch from "@/components/shop/menu/GoodsSearch.vue";
export default {
  data() {
    return {
      id: this.$route.params.id || null,
      categoryPage: 0,
      page: 1,
      sort: "newly",
      searchText: "",
      isFirst: true,
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", null);
    this.$store.dispatch("shop/dataClear");
    this.getMainMenu();
  },
  components: {
    MainMenu,
    GoodsCategory,
    GoodsSearch,
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("shop", [
      "result",
      "msg",
      "categories",
      "listArray",
      "totalPage",
      "limit",
    ]),
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    listSort(sort, event) {
      [...document.querySelectorAll(".list_sort")].map((sort) =>
        sort.classList.remove("on")
      );
      event.target.classList.add("on");
      this.sort = sort;
      this.getMarketMainList(true);
    },
    getGoodsCategory(id) {
      this.id = id;
      this.getMarketMainList(true);
    },
    getDataSearch() {
      //전혀 다른 검색화면
    },
    won(str) {
      return this.$won(str);
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.getMarketMainList(true, true);
    },
    async getMarketMainList(reset = false, first = false) {
      if (reset) {
        this.categoryPage = 0;
      }
      await this.$store.dispatch("shop/getMarketMainList", {
        sort: this.sort,
        categrory_page: this.categoryPage,
        page: this.page,
        reset,
        first,
        id: this.id,
      });
      this.categoryPage++;

      if (reset && first) {
        // this.$refs.goodsCategory.reflash();
        let index = this.categories.findIndex(
          (x) => parseInt(x.id) === parseInt(this.id)
        );
        if (index === -1) {
          this.$refs.goodsCategory.reflash(0);
        } else {
          this.$refs.goodsCategory.reflash(index);
        }
      } else {
        this.$router.push(`/market_main_list/${this.id}`);
      }
    },
    getMenu(num) {
      if (parseInt(num) == 1) {
        this.id = this.categories[0].id;
        this.getMarketMainList(true, true);

        return false;
      }
      if (parseInt(num) == 3) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sect_location {
  span {
    color: black;
    font-weight: 600;
  }
}
</style>
